"use client"
import { Property } from "@/@types/global"
import useQueryParams from "@/components/hook/use-params"
import { getPropertiesCloseToMe, useProperties } from "@/services/property"
import { useLocationStore } from "@/store/location"
import { useRouter } from "next/navigation"
import { useEffect, useRef, useState } from "react"

export const useHome = () => {
  const { getParam, setParams, params } = useQueryParams()
  const router = useRouter()
  const { location } = useLocationStore()
  const [propertiesVisible, setPropertiesVisible] = useState<Property[]>([])
  const [zoom, setZoom] = useState(12)
  const [page, setPage] = useState(Number.parseInt(getParam('page')[0]) || 1)
  const refCloseMap = useRef<HTMLDivElement>(null)
  const mapInput = useRef<HTMLInputElement>(null)
  const { data: properties, isLoading } = useProperties({ pag: page })
  
  const { propertiesCloseToMe } = getPropertiesCloseToMe(location)
  const updatePage = (newPage: number) => {
    setPage(newPage)
    const params = setParams('page', newPage.toString())
    router.push(`?${params}`)
  }

  const pageNext = () => {
    if (properties.hasNext) {
      updatePage(page + 1)
    }
  }

  const pagePrev = () => {
    if (properties.hasPrev) {
      updatePage(page - 1)
    }
  }

  const handleSelectChange = (label: string, value: string) => {
    let newParams: string = ""
    if (label === 'price') {
      newParams = setParams('sortdir', '')
      if (value === 'Preço decrescente') {
        newParams = setParams('sortdir', 'desc')
      } else if (value === 'Preço crescente') {
        newParams = setParams('sortdir', 'asc')
      }
    } else {
      newParams = setParams(label, value)
    }
    router.push(`?${newParams}`)
    return
  }
  
  useEffect(() => {
    const propertiesVisible = JSON.parse(
      localStorage.getItem('propertiesVisible') || '[]',
    )
    setPropertiesVisible(propertiesVisible)
  }, [params])

  return {
    handleSelectChange,
    pagePrev,
    pageNext,
    page,
    isLoading,
    properties,
    propertiesCloseToMe,
    propertiesVisible,
    refCloseMap,
    mapInput,
    zoom,
    setZoom,
    updatePage,
    location
  }
}