"use client";
import FilterOptions, { FilterOptionsInfoState } from "@/app/(main)/_components/filter-options";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2, MapPin, Search, SlidersHorizontal, Star } from "lucide-react";
import Link from "next/link";
import { type RefObject, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useModal } from "../../contexts/modal-provider";
import { Button } from "../ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { useRouter, useSearchParams } from "next/navigation";
import CustomModal from "../globals/custom-model";
import { useJsApiLoader } from "@react-google-maps/api";
import { useLocationStore } from "@/store/location";

const filter = z.object({
  location: z.string().optional(),
  priceRange: z.object({
    label: z.string(),
    values: z.tuple([z.number(), z.number()]),
  }),
});

type Props = {
  inputAutoComplete: RefObject<HTMLInputElement>;
};

export default function FormFilterHome({ inputAutoComplete }: Props) {
  const router = useRouter()
  // const { setPlace } = usePlaces()
  const { setPlace } = useLocationStore()
  const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.NEXT_PUBLIC_MAPS_API_KEY!,
		libraries: ["core", "maps", "places", "marker"],
		language: "pt-BR",
	});

  const searchParams = useSearchParams();
  const [isLoading] = useState(false);
  const { setOpen, setClose } = useModal();
  const form = useForm<z.infer<typeof filter>>({
    resolver: zodResolver(filter),
  });


  const objToParams = (obj: FilterOptionsInfoState) => {
    const params = new URLSearchParams(searchParams.toString());
    Object.keys(obj).forEach((key) => {
      const value = obj[key as keyof FilterOptionsInfoState];
      if (Array.isArray(value)) {
        params.delete(key);
        value.forEach((val) => {
          params.append(key, val.toString());
        });
      } else if (value == null || value === "") {
        params.delete(key);
      } else {
        params.set(key, value.toString());
      }
    });
    return params.toString();
  }

  const handleSubmit = (obj: FilterOptionsInfoState) => {
    const params_ = objToParams(obj);
    router.push(`?${params_}`);
    setClose();
  };

  const handleOptionsFilter = () => {
    setOpen(
      <CustomModal subheading="Todos os filtros que deseja" className="max-w-[600px] w-full">
        <FilterOptions onSubmit={handleSubmit} />
      </CustomModal>
    );
  };

  useEffect(() => {
    if (isLoaded) {
      const gAutoComplete = new google.maps.places.Autocomplete(
        inputAutoComplete.current as HTMLInputElement,
        {
          types: ["geocode"],
          componentRestrictions: { country: "BR" },
        }
      );
      gAutoComplete.addListener("place_changed", () => setPlace(gAutoComplete.getPlace()));
    }
  }, [isLoaded, inputAutoComplete, setPlace]);

  return (
    <div className="-mt-14 max-lg:-mt-36 flex w-full justify-center max-sm:mb-14">
      <Form {...form}>
        <div className="max-lg:bg-red z-50 flex w-[80%] items-end justify-between gap-2 rounded-[12px] border border-[#000000]/20 bg-white p-6 max-xl:w-full max-lg:flex-col max-sm:flex-col">
          <div className="grid w-full flex-1 grid-cols-2 items-end justify-center gap-x-10 max-xl:grid-cols-2 max-md:grid-cols-1 max-sm:flex max-sm:w-full max-sm:flex-col lg:flex lg:grid-cols-6">
            <div className="h-full w-[.8px] bg-black/10 max-2xl:sr-only" />
            <FormField
              control={form.control}
              name="location"
              render={() => (
                <FormItem className="min-h:[450px] col-span-1 w-full max-xl:col-span-2 max-md:col-span-1">
                  <FormLabel className="text-base text-[#3c4453]">Endereço</FormLabel>
                  <FormControl className="w-full">
                    <div className="flex w-full items-center gap-2 rounded-md border border-[#d0d5dd] border-input px-3 py-1 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                      <Input
                        ref={inputAutoComplete}
                        placeholder="Localização"
                        className="w-full border-none px-0 py-0"
                      />
                      <MapPin size={24} />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex flex-wrap items-center gap-4 max-sm:w-full">
            <Button className="w-[12.5rem] gap-2 p-6 max-sm:flex-1">
              {isLoading && <Loader2 className="animate-spin" />}
              {!isLoading && (
                <Link href={"/search"} className="flex gap-2 p-6 max-sm:flex-1">
                  <Search size={20} />
                  MOSTRAR LISTA
                </Link>
              )}
            </Button>
            <Button
              variant={"outline"}
              onClick={handleOptionsFilter}
              className="border-[#000000]/15 gap-2 p-6 max-sm:flex-1"
            >
              <SlidersHorizontal size={20} />
              BUSCAR NO MAPA
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}
