import Image from "next/image"
import { useHome } from "./home.model"
import FormFilterHome from "@/components/forms/form-filter-home"
import { FILTER_HOME_IMOVEIS } from "@/constant/filters"
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Property } from "@/@types/global"
import { Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from "@/components/ui/pagination"
import { InfiniteMovingCards } from "@/components/ui/infinite-moving-cards"
import { SwiperSlide } from "swiper/react"
import Faq from "./_component/faq"
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import dynamic from "next/dynamic"
import CardGridView from "../_components/cards/views/card-grid.view"
import { MousePointerClick } from "lucide-react"


type HomeProps = ReturnType<typeof useHome>

const Maps = dynamic(
  () => {
      return import('../../../components/globals/maps/views/google-map.view')
  },
  { ssr: false },
)
export const HomeView = ({ handleSelectChange, page, isLoading, properties, mapInput, pageNext, pagePrev, propertiesCloseToMe, propertiesVisible, refCloseMap, setZoom, updatePage, zoom }:HomeProps) => {
  return (
    <>
      {/* Filters and Google map */}
      <section>
        <div className="relative h-[650px] w-full">
          <div
            className="bg-red-150 absolute left-0 top-0 z-[1] flex h-full w-full items-center justify-center backdrop-blur-sm"
            ref={refCloseMap}
          >
            <div
              className="inline-flex cursor-pointer gap-4 rounded-lg bg-white px-6 py-3 hover:brightness-[1.15]"
              onClick={() => refCloseMap.current?.classList.add('hidden')}
            >
              <MousePointerClick />
              <p className="text-base text-[#3c4453]">
                Clique para abrir o mapa
              </p>
            </div>
          </div>
          <Maps zoom={zoom} setZoom={setZoom} setQuantity={()=> 0} />
        </div>
        <FormFilterHome inputAutoComplete={mapInput} />
      </section>
      {/* App listing */}
      <section
        className="mx-auto mt-[50px] max-w-[1420px]"
        id="property-listing"
      >
        <div className="flex max-md:flex-col gap-4 w-full max-md:p-2 flex-wrap items-center justify-between">
          <h1 className="text-2xl font-[400] max-md:text-center">
            Confira os <strong className="italic">Imóveis destaque</strong>
          </h1>
          <div className="flex items-center justify-between gap-[0.625rem] sm:w-2/5">
            {(
              Object.keys(FILTER_HOME_IMOVEIS) as Array<
                keyof typeof FILTER_HOME_IMOVEIS
              >
            ).map((key) => {
              const value = FILTER_HOME_IMOVEIS[key]
              return (
                <Select
                  key={value.label}
                  onValueChange={(v) => {
                    handleSelectChange(value.label, v)
                  }}
                >
                  <SelectTrigger className="flex h-11 w-full items-center gap-2 rounded-md border border-[#d0d5dd] px-3 py-0.5 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                    <SelectValue placeholder={key} />
                  </SelectTrigger>
                  <SelectContent className="outline-none focus:outline-none">
                    <SelectGroup>
                      <SelectLabel>{key}</SelectLabel>
                      {value.values.map((v) => (
                        <SelectItem key={v} value={v}>
                          {v}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              )
            })}
          </div>
        </div>
        <div className="mt-3 flex flex-col max-md:p-2 flex-wrap justify-between gap-4 p-4 max-md:gap-2 sm:flex-row">
          {isLoading &&
            Array.from({ length: 8 }).map((_, index) => (
              <div className="space-y-4" key={index}>
                <div className="h-[300px] min-w-[300px] animate-skeleton rounded-md bg-gray-200/80 bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 bg-200%" />
              </div>
            ))}
          <div className="grid grid-cols-12 gap-x-2 gap-y-2">
            {!isLoading &&
              properties?.data.map((i: Property) => (
                <div className="w-full lg:col-span-3 max-lg:col-span-4 max-md:col-span-6 max-[490px]:col-span-12">
                  <CardGridView key={i.id} data={i} compared />
                </div>
              ))}
          </div>
          {!isLoading && properties && (
            <Pagination className="inline-flex">
              <PaginationContent className="space-x-2">
                <PaginationItem>
                  <PaginationPrevious
                    href="#property-listing"
                    onClick={pagePrev}
                    className="flex items-center justify-center border"
                    aria-disabled={page === 1}
                    tabIndex={page === 1 ? -1 : 0}
                  />
                </PaginationItem>

                {/* Primeira página */}
                {page > 1 && (
                  <PaginationItem onClick={() => updatePage(1)} className={page === 1 ? 'sr-only' : ''}>
                    <PaginationLink href="#property-listing">1</PaginationLink>
                  </PaginationItem>
                )}

                {/* Elipse antes da página atual */}
                {page > 2 && <PaginationItem><PaginationEllipsis /></PaginationItem>}

                {/* Página anterior */}
                {page > 1 && (
                  <PaginationItem>
                    <PaginationLink href="#property-listing" onClick={() => updatePage(page - 1)}>
                      {page - 1}
                    </PaginationLink>
                  </PaginationItem>
                )}

                {/* Página atual */}
                <PaginationItem>
                  <PaginationLink href="#property-listing" isActive>
                    {page}
                  </PaginationLink>
                </PaginationItem>

                {/* Próxima página */}
                {page < properties.end && (
                  <PaginationItem>
                    <PaginationLink href="#property-listing" onClick={() => updatePage(page + 1)}>
                      {page + 1}
                    </PaginationLink>
                  </PaginationItem>
                )}

                {/* Elipse depois da página atual */}
                {page < properties.end - 1 && <PaginationItem><PaginationEllipsis /></PaginationItem>}

                {/* Última página */}
                {page !== properties.end && (
                  <PaginationItem onClick={() => updatePage(properties.end)}>
                    <PaginationLink href="#property-listing">
                      {properties.end}
                    </PaginationLink>
                  </PaginationItem>
                )}

                <PaginationItem>
                  <PaginationNext
                    href="#property-listing"
                    onClick={pageNext}
                    className="flex items-center justify-center border"
                    aria-disabled={page === properties.end}
                    tabIndex={page === properties.end ? -1 : 0}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          )}
        </div>
      </section>
      {/* Properties seen by you */}
      {propertiesCloseToMe.data?.length > 0 && propertiesCloseToMe?.data && (
        <section className="mx-auto mt-[50px] max-w-[1420px]">
          <div className="flex w-full flex-col flex-wrap justify-between gap-10">
            <h1 className="text-2xl font-[400] max-md:text-center">
              Imóveis <strong className="italic">próximos à você</strong>{' '}
            </h1>
            <InfiniteMovingCards>
              {propertiesCloseToMe?.data?.map((i: Property, idx: number) => (
                <SwiperSlide>
                  <CardGridView data={i} key={i.id} />
                </SwiperSlide>
              ))}
            </InfiniteMovingCards>
          </div>
        </section>
      )}
      {/* Last properties seen by you */}
      {propertiesVisible.length > 0 && propertiesVisible && (
        <section className="mx-auto mt-[50px] max-w-[1420px] p-4">
          <div className="flex w-full flex-wrap justify-between gap-10">
            <div className="flex w-full flex-wrap items-center justify-between max-sm:flex-col max-sm:gap-2 max-md:text-center">
              <h1 className="text-2xl font-[400] max-md:text-center w-full">
                Últimos imóveis{' '}
                <strong className="italic">visto por você</strong>
              </h1>
            </div>
            <InfiniteMovingCards>
              {propertiesVisible &&
                propertiesVisible?.map((i: Property) => (
                  <SwiperSlide>
                    <CardGridView data={i} compared key={i.id} />
                  </SwiperSlide>
                ))}
            </InfiniteMovingCards>
          </div>
        </section>
      )}
      {/* All properties in */}
      <section className="mt-10 flex flex-col items-center justify-center">
        <div>
          <h1 className="relative max-md:text-xl inline-flex text-wrap text-center text-3xl font-[400]">
            Todos os imóveis em{' '}
            <strong className="italic ml-4"> um só lugar</strong>
            <Image
              className="absolute md:-bottom-1 max-md:-bottom-1 md:w-[200px] max-md:mt-10 right-0"
              alt=""
              src={'/ondula.svg'}
              width={120}
              height={9}
            />
          </h1>
        </div>
        <div className=" flex items-center relative max-md:flex-col">
          <div className="flex max-md:mt-4">
            <Image alt="" src={'/homecelular.png'} width={1200} height={800} />
          </div>

          <div className="inline-flex flex-wrap gap-4 max-md:justify-center">
            <div className="max-sm:w-full max-sm:p-6 sm:max-w-[400px]">
              <h6 className="text-lg font-semibold">Aumente suas vendas</h6>
              <p className="text-justify text-sm text-[#7B818F]">
                Anuncie seu imóvel de forma gratuita e potencialize suas vendas.
              </p>
            </div>
            <div className="max-sm:w-full max-sm:p-6 sm:max-w-[400px]">
              <h6 className="text-lg font-semibold">Visibilidade garantida</h6>
              <p className="text-justify text-sm text-[#7B818F]">
                Explore milhões de imóveis em nosso mapa interativo.
              </p>
            </div>
            <div className="max-sm:w-full max-sm:p-6 sm:max-w-[400px]">
              <h6 className="text-lg font-semibold">
                Impulsione suas vendas com o MINB
              </h6>
              <p className="text-justify text-sm text-[#7B818F]">
                Utilize nossas ferramentas para maximizar suas oportunidades de
                venda.
              </p>
            </div>
            <div className="max-sm:w-full max-sm:p-6 sm:max-w-[400px]">
              <h6 className="text-lg font-semibold">
                Pesquise com facilidade e praticidade
              </h6>
              <p className="text-justify text-sm text-[#7B818F]">
                Tenha acesso a um painel exclusivo para imobiliárias e
                corretores, e mantenha-se atualizado sobre o mercado
                imobiliário.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Questions */}
      <section className="container">
        <Faq />
      </section>
    </>
  )
}